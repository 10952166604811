import React, {useEffect} from "react";
import { Modal } from "react-bootstrap";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../../wallet/Connect";
import { useNavigate } from "react-router-dom";
import { link_discord, link_opensea, link_twitter } from "../../data/links";
import NavbarIconItem from "../pages/stake/NavbarIconItem";
import NavbarItem from "../pages/stake/NavbarItem";
import "./style.css"

const MobileMenuModal = ({showModal, onCloseModal, setPage}) => {

    const navigate = useNavigate();

    const { active, account, activate, deactivate } = useWeb3React();

	const checkIfWalletIsConnected = async () => {
		try {
			if (!active) await activate(injected);
		} catch (ex) {
			console.log(ex);
		}
	};

	useEffect(() => {
		checkIfWalletIsConnected();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onClickConnect = async () => {
		try {
			if (!active) await activate(injected);
			else deactivate();
		} catch (ex) {
			console.log(ex);
		}
	};

	const displayUserAddress = () => {
		if (active) {
			let firstPart = account.slice(0, 6);
			let endPart = account.slice(account.length - 4);
			return firstPart + "..." + endPart;
		} else {
			return "CONNECT";
		}
	};

    return(
        <>
            <Modal            
                show={showModal}
                onHide = {onCloseModal}
                className="mobile_nav_modal "
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className="modal_body">
                    <div className="icon_wrap">
                        <NavbarIconItem
                            link={link_opensea}
                            iconUrl="/images/opensea.png"
                            alt="OpenSea"
                            className="opensea"
                        />
                        <NavbarIconItem
                            link={link_discord}
                            iconUrl="/images/discord.png"
                            alt="Discord"
                            className="discord"
                        />
                        <NavbarIconItem
                            link={link_twitter}
                            iconUrl="/images/twitter.png"
                            alt="Twitter"
                        />
                    </div>
                    <div>
                        <NavbarItem
                            text="Home"
                            right_value=">"
                            onClickItem={() => navigate("/")}
                            
                        />
                        <NavbarItem
                            text="THE DOCK"
                            right_value=">"
                            onClickItem={() => setPage(1)}
                        />
                        <NavbarItem text="ALPHA MINT" right_value=">" onClickItem={() => setPage(0)} />
                        {/* <NavbarItem
                            text=""
                            onClickItem={() => setPage(2)}
                        /> */}
                        <NavbarItem text="MARKET" right_value=">" onClickItem={() => setPage(3)} />
                    </div>
                    <div className="button_wrap">
                        <button className="connect_button" onClick={() => onClickConnect()}>
                            {displayUserAddress()}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MobileMenuModal;