const NavbarItem = ({ text, onClickItem, right_value }) => {
	return (
		<div className="Navbar_Item" onClick={onClickItem}>
			<span>{text}</span>
			<div>{right_value}</div>
		</div>
	);
};

export default NavbarItem;
