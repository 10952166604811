import axios from "axios";
import {
    OPENSEA_TESTNET_API, OPENSEA_MAINNET_API
} from "../data";
//2b069923a89d416aa68613d5543306e0//
export const getTokenIdList = async (userAddress, tokenAddress) => {
    let tokenIdList = [];
    let next = "";
    try {
        do {
            let response = await axios.get(OPENSEA_MAINNET_API, {
                headers: {
                    'X-Api-Key': "417bef6238b44fd588627bd920cc8e4d",
                },
                params: {
                    owner: userAddress,
                    asset_contract_address: tokenAddress,
                    order_direction: "desc",
                    limit: "50",
                    cursor:next,
                },
            });
            next = response.data.next;
            if (response.status === 200) {
                response.data.assets.map((one) => tokenIdList.push({ id: one.token_id, image: one.image_url }));
                // response.data.assets.map((one) => tokenIdList.push(one.token_id));
            }
        } while (next);
        
    } catch (error) {
        console.log("___ error ___", error);
    }
    return tokenIdList;
};