import React, { useEffect, useState } from "react";
import { pngs } from "../../../../assets/pngs";
import Nav from "../../../elements/Nav";
import NavMobile from "../../../elements/NavMobile";
import Socials from "../../../elements/Socials";

const Header = () => {
    const [bg, setBg] = useState(true);

    return (
        <header
            className={`${
                bg ? "bg-tertiary h-20" : "h-24"
            } flex items-center fixed top-0 w-full text-white z-10 transition-all duration-300 home_header`}
        >
            <div className="container mx-auto h-full flex items-center justify-between">
                <a target="_blank" href="https://discord.gg/odyc">
                    <img src={pngs.logo} alt="" />
                </a>
                <div className="hidden lg:block">
                    <Nav />
                </div>
                <div className=" lg:block">
                    <Socials />
                </div>
                <div className="lg:hidden" style={{display:"none"}}>
                    <NavMobile />
                </div>
            </div>
        </header>
    );
};

export default Header;
