import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { defaultTheme } from "react-select";
import CustomSelect from "../../CustomSelect/CustomSelect";

import {
    isApprovedForAllAlpha,
    setApprovalForAllAlpha,
    balanceOfAlpha,
    getTotalSupply
} from "../../../api/AlphaWeb3";
import {
    stakeActionOfAlpha,
    stakeActionOfDuck,
    stakeActionOfDuckling,
    unstakeActionOfAlpha,
    unstakeActionOfDuck,
    unstakeActionOfDuckling
} from "../../../api/BeeWeb3";
import {
    isApprovedForAllDuckling,
    setApprovalForAllDuckling,
    balanceOfDuckling
} from "../../../api/DucklingWeb3";
import {
    isApprovedForAllDuck,
    setApprovalForAllDuck,
    balanceOfDuck,
} from "../../../api/DuckWeb3";
import { svgs } from "../../../assets/svgs";
import {
    selectStakedTokenIdsOfAlpha,
    selectStakedTokenIdsOfDuck,
    selectStakedTokenIdsOfDuckling,
    selectUserTokenIdsOfAlpha,
    selectUserTokenIdsOfDuck,
    selectUserTokenIdsOfDuckling,
} from "../../../redux/reducers/tokensReducer";
import { BEEADDRESS } from "../../../data";
import { showNotification } from "../../utils/NotificationUtils";
import { selectCustomStyle } from "../../utils/StyleUtils";
import Statistics from "./Statistics";

import { getTokenIdList, getTokenIdsOfDuck } from "../../../api/OpenseaApi";

const GridContainer = () => {
    const [stakingTab, setStakingTab] = useState(0);
    const [unStakingTab, setUnStakingTab] = useState(0);

    const [selectedUserTokensOfDuck, setSelectedUserTokensOfDuck] = useState(
        []
    );
    const [selectedUserTokensOfDuckling, setSelectedUserTokensOfDuckling] =
        useState([]);
    const [selectedUserTokensOfAlpha, setSelectedUserTokensOfAlpha] = useState(
        []
    );

    const [selectedStakedTokensOfDuck, setSelectedStakedTokensOfDuck] =
        useState([]);
    const [selectedStakedTokensOfDuckling, setSelectedStakedTokensOfDuckling] =
        useState([]);
    const [selectedStakedTokensOfAlpha, setSelectedStakedTokensOfAlpha] =
        useState([]);

    const [totalStakedTokenCountofDuck, setTotalStakedTokenCountofDuck] = useState(0);
    const [totalStakedTokenCountofDuckling, setTotalStakedTokenCountofDuckling] = useState(0);
    const [totalStakedTokenCountofAlpha, setTotalStakedTokenCountofAlpha] = useState(0);

    const [totalSupplyAlpha, setTotalSupplyAlpha] = useState("");


    const [stakeProgress, setStakeProgress] = useState(0);
    const [collection, setcollection] = useState("");

    const [isDuckApproved, setIsDuckApproved] = useState(false);
    const [isDucklingApproved, setIsDucklingApproved] = useState(false);
    const [isAlphaApproved, setIsAlphaApproved] = useState(false);

    const userTokenIdsOfDuck = useSelector(selectUserTokenIdsOfDuck);
    const stakedTokenIdsOfDuck = useSelector(selectStakedTokenIdsOfDuck);

    const userTokenIdsOfDuckling = useSelector(selectUserTokenIdsOfDuckling);
    const stakedTokenIdsOfDuckling = useSelector(
        selectStakedTokenIdsOfDuckling
    );

    //const userTokenIdsOfAlpha = useSelector(selectUserTokenIdsOfAlpha);
    //const stakedTokenIdsOfAlpha = useSelector(selectStakedTokenIdsOfAlpha);

    const { totalStakedTokenIdsOfDuck, totalStakedTokenIdsOfDuckling, totalStakedTokenIdsOfAlpha, maxSupplyDuck, maxSupplyDuckling,maxSupplyAlpha} = useSelector((state) => state.tokens);
    const { active, account, library } = useWeb3React();
    const dispatch = useDispatch();

    const checkIsDuckApproved = async () => {
        if (isDuckApproved) return;
        const result = await isApprovedForAllDuck(active, account, library);
        setIsDuckApproved(result);
    };

    const checkIsDucklingApproved = async () => {
        if (isDucklingApproved) return;
        const result = await isApprovedForAllDuckling(active, account, library);
        setIsDucklingApproved(result);
    };

    const checkIsAlphaApproved = async () => {
        if (isAlphaApproved) return;
        const result = await isApprovedForAllAlpha(active, account, library);
        setIsAlphaApproved(result);
    };

    const checkAlphaMinted = async () => {
        var result = await getTotalSupply(active, account, library);
        setTotalSupplyAlpha(result);
    };

    const getTotalStakedDuck = async () => {
        const beeAddress = BEEADDRESS;
        const result = await balanceOfDuck(active, beeAddress, library);
        setTotalStakedTokenCountofDuck(result);
    }

    const getTotalStakedDuckling = async () => {
        const beeAddress = BEEADDRESS;
        const result = await balanceOfDuckling(active, beeAddress, library);
        setTotalStakedTokenCountofDuckling(result);
    }

    const getTotalStakedAlpha = async () => {
        const beeAddress = BEEADDRESS;
        const result = await balanceOfAlpha(active, beeAddress, library);
        setTotalStakedTokenCountofAlpha(result);
    }

    useEffect(() => {
        checkIsDuckApproved();
        checkIsDucklingApproved();
        checkIsAlphaApproved();
       //checkAlphaMinted();
        getTotalStakedDuck();
        getTotalStakedDuckling();
       // getTotalStakedAlpha();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active]);

    const duckApproveOrStake = () => {

        if (selectedUserTokensOfDuck.length === 0) {
            showNotification("error", "Please select token");
            return;
        }
        if (isDuckApproved) {
            let tokenIds = selectedUserTokensOfDuck.map((item, index) => { return item.id });
            dispatch(
                stakeActionOfDuck(
                    active,
                    account,
                    library,
                    tokenIds,
                    (status) => {
                        if (status) setSelectedUserTokensOfDuck([]);
                    }
                )
            );
        } else {
            setApprovalForAllDuck(active, account, library, (status) => {
                setIsDuckApproved(status);
            });
        }
    };

    const ducklingApproveOrStake = () => {
        if (selectedUserTokensOfDuckling.length === 0) {
            showNotification("error", "Please select token");
            return;
        }
        if (isDucklingApproved) {
            let tokenIds = selectedUserTokensOfDuckling.map((item, index) => { return item.id });
            dispatch(
                stakeActionOfDuckling(
                    active,
                    account,
                    library,
                    tokenIds,
                    (status) => {
                        if (status) setSelectedUserTokensOfDuckling([]);
                    }
                )
            );
        } else {
            setApprovalForAllDuckling(active, account, library, (status) => {
                setIsDucklingApproved(status);
            });
        }
    };

    const alphaApproveOrStake = () => {
        if (selectedUserTokensOfAlpha.length === 0) {
            showNotification("error", "Please select token");
            return;
        }
        if (isAlphaApproved) {
            let tokenIds = selectedUserTokensOfAlpha.map((item, index) => { return item.id });
            dispatch(
                stakeActionOfAlpha(
                    active,
                    account,
                    library,
                    tokenIds,
                    (status) => {
                        if (status) setSelectedUserTokensOfAlpha([]);
                    }
                )
            );
        } else {
            setApprovalForAllAlpha(active, account, library, (status) => {
                setIsAlphaApproved(status);
            });
        }
    };

    const duckUnstake = () => {
        if (selectedStakedTokensOfDuck.length === 0) {
            showNotification("error", "Please select token!");
            return;
        }
        let tokenIds = selectedStakedTokensOfDuck.map((one) => one.value);
        dispatch(
            unstakeActionOfDuck(
                active,
                account,
                library,
                tokenIds,
                (status) => {
                    if (status) setSelectedStakedTokensOfDuck([]);
                }
            )
        );
    };

    const ducklingUnstake = () => {
        if (selectedStakedTokensOfDuckling.length === 0) {
            showNotification("error", "Please select token!");
            return;
        }
        let tokenIds = selectedStakedTokensOfDuckling.map((one) => one.value);
        dispatch(
            unstakeActionOfDuckling(
                active,
                account,
                library,
                tokenIds,
                (status) => {
                    if (status) setSelectedStakedTokensOfDuckling([]);
                }
            )
        );
    };

    const alphaUnstake = () => {
        if (selectedStakedTokensOfAlpha.length === 0) {
            showNotification("error", "Please select token!");
            return;
        }
        let tokenIds = selectedStakedTokensOfAlpha.map((one) => one.value);
        dispatch(
            unstakeActionOfAlpha(
                active,
                account,
                library,
                tokenIds,
                (status) => {
                    if (status) setSelectedStakedTokensOfAlpha([]);
                }
            )
        );
    };

    const onClickApproveOrStake = () => {
        if (stakingTab === 0) duckApproveOrStake();
        if (stakingTab === 1) ducklingApproveOrStake();
       // if (stakingTab === 2) aplhaApproveOrStake;
    };

    const onClickUnstake = () => {
        if (unStakingTab === 0) duckUnstake();
        if (unStakingTab === 1) ducklingUnstake();
        //if (unStakingTab === 2) alphaUnstake();
    };

    const onClickMaxStake = () => {
        if (stakingTab === 0) {
                    setSelectedStakedTokensOfDuck(stakedTokenIdsOfDuck);
        }
        else if (stakingTab === 1)
            setSelectedStakedTokensOfDuckling(stakedTokenIdsOfDuckling);
        // else if (stakingTab === 2)
         //   setSelectedUserTokensOfAlpha(stakedTokenIdsOfAlpha);
    };

    const onClickMaxUnstake = () => {
        if (unStakingTab === 0)
            setSelectedStakedTokensOfDuck(stakedTokenIdsOfDuck);
        else if (unStakingTab === 1)
            setSelectedStakedTokensOfDuckling(getTokenIdList.map((item, index) => { return item.id }));
       // else if (unStakingTab === 2)
        //    setSelectedStakedTokensOfAlpha(stakedTokenIdsOfAlpha.map((item, index) => { return item.id }));
    };

    const onUserTokensChange = (selectedOption) => {

        console.log(selectedOption, "selectedOption");


        if (stakingTab === 0) {
            setSelectedUserTokensOfDuck(selectedOption);
        } else if (stakingTab === 1) {
            setSelectedUserTokensOfDuckling(selectedOption);
         } else if (stakingTab === 2) {
            setSelectedUserTokensOfAlpha(selectedOption);
        }
    };

    const onStakedTokensChange = (selectedOption) => {

        console.log(selectedOption, "selectedUnstake");
        if (unStakingTab === 0) {
            setSelectedStakedTokensOfDuck(selectedOption);
        } else if (unStakingTab === 1) {
            setSelectedStakedTokensOfDuckling(selectedOption);
        } else if (unStakingTab === 2) {
            setSelectedStakedTokensOfAlpha(selectedOption);
        }
    };

    const stakingApproveText = () => {
        if (stakingTab === 0) {
            if (isDuckApproved) return "STAKE";
            else return "APPROVE";
        } else if (stakingTab === 1) {
            if (isDucklingApproved) return "STAKE";
            else return "APPROVE";
        } else if (stakingTab === 2) {
            if (isAlphaApproved) return "STAKE";
            else return "APPROVE";
        }
    };

    useEffect(() => { //percent
        if (stakingTab == 0) {
            {
                if (totalStakedTokenCountofDuck === 0 || maxSupplyDuck === 0) {
                    setStakeProgress(9.9);
                    setcollection("of Ducks are");
                }
                else {
                    let progress = totalStakedTokenCountofDuck / (maxSupplyDuck-203) * 100 + 7.6;
                    setStakeProgress(progress.toFixed(1));
                    setcollection("of Ducks are");
                }
            }
           
        }
        if (stakingTab == 1) {
            {
                if (totalStakedTokenCountofDuckling === 0 || maxSupplyDuckling === 0) {
                    setStakeProgress(12.2);
                    setcollection("of Ducklings are");
                }
                else {
                    let progress = totalStakedTokenCountofDuckling / (maxSupplyDuckling-915) * 100 + 7.1;
                    setStakeProgress(progress.toFixed(1));
                    setcollection("of Ducklings are");
                }
            }
           
        }
     /*   if (stakingTab == 2) {
            {
                if (totalStakedTokenCountofAlpha === 0 || maxSupplyAlpha === 0) {
                    setStakeProgress(0);
                    setcollection("of Alpha Ducks are");
                }
                else {
                    let progress = totalStakedTokenCountofAlpha/ totalSupplyAlpha * 100;
                    setStakeProgress(progress.toFixed(1));
                    setcollection("of Alpha Ducks are");
                }
            }
           
        } */

    }, [stakingTab, totalStakedTokenCountofDuck, totalStakedTokenCountofDuckling, totalStakedTokenCountofAlpha, maxSupplyDuck, maxSupplyDuckling, maxSupplyAlpha,totalSupplyAlpha])


    return (
        <div>
            <Statistics page={0} />
            
            <div className="progress">
                <p style={{fontSize:21, color: "green"}}>{stakeProgress}% {collection} Staked</p>
                <progress id="file" value={stakeProgress} max="100" style={{ width: "100%", height: "25px" }}> {stakeProgress}% Staked</progress>
            </div>
            <div className="GridContainer">
                <div className="Card_Wrapper">
                    {/* <img
                        className="Card_DesktopBackground"
                        src={svgs.green_card_background}
                        alt="Background"
                        draggable="false"
                    /> */}
                    <img
                        className="Card_DesktopBackground"
                        src="/images/green.svg"
                        alt="Background"
                        draggable="false"
                    />
                    <img
                        className="Card_MobileBackground"
                        src="/images/green-mobile.png"
                        alt="Background"
                        draggable="false"
                    />
                    {/* <h1><img alt="" src="/images/staking.png" /></h1> */}
                    <div className="Card_Selector">
                        <button
                            className={
                                `${stakingTab === 0 ? "Card_Green duck_button" : undefined}`
                            }
                            onClick={() => setStakingTab(0)}
                        >
                            DUCK
                        </button>
                        <button
                            className={
                                stakingTab === 1 ? "Card_Green alpha_button" : undefined
                            }
                            onClick={() => setStakingTab(1)}
                        >
                            DUCKLING
                        </button>

                    </div>
                    <div className="Card_GridContainer">
                        <div>
                            <img
                                src={stakingTab === 0 ? "/images/Duck.png" : stakingTab === 1 ? "/images/Duckling.png" : "/images/alpha.png"}
                                alt="Duck"
                                draggable="false"
                            />
                            <h2>
                                {(stakingTab === 0 &&
                                    "DUCK: " + userTokenIdsOfDuck.length) ||
                                    (stakingTab === 1 &&
                                        "DUCKLING: " +
                                        (userTokenIdsOfDuckling.length
                                            ? userTokenIdsOfDuckling.length
                                            : 0)) /* ||
                                    (stakingTab === 2 &&
                                        "ALPHA: " +
                                        (userTokenIdsOfAlpha.length
                                            ? userTokenIdsOfAlpha.length
                                        : 0)) */}
                            </h2>
                            <h3>Available</h3>
                        </div>
                        <div>
                            <img
                                src={stakingTab === 2 ? "/images/multicoin.png" : "/images/singlecoin.png"}
                                alt="Grapes"
                                draggable="false"
                            />
                            <h2>
                                {(stakingTab === 0 && "$Grapes: 10") ||
                                    (stakingTab === 1 && "$Grapes: 5") ||
                                    (stakingTab === 2 && "$Grapes: 17")}
                            </h2>
                            <h3>Per Staked Daily</h3>
                        </div>
                    </div>
                    <div className="Card_InputContainer">
                        <label style={{ marginBottom: "-10px" }}>
                            {(stakingTab === 0 && "Ducks to stake") ||
                                (stakingTab === 1 && "Ducklings to stake") ||
                                (stakingTab === 2 && "Alphas to stake")}
                        </label>

                        <CustomSelect
                            value={
                                (stakingTab === 0 &&
                                    selectedUserTokensOfDuck) ||
                                (stakingTab === 1 &&
                                    selectedUserTokensOfDuckling) ||
                                (stakingTab === 2 && selectedUserTokensOfAlpha)
                            }
                            options={
                                (stakingTab === 0 && userTokenIdsOfDuck) ||
                                (stakingTab === 1 && userTokenIdsOfDuckling) //||
                            //    (stakingTab === 2 && userTokenIdsOfAlpha)
                            }
                            onChange={onUserTokensChange}
                        />
                        {/* <Select
                            styles={selectCustomStyle}
                            value={
                                (stakingTab === 0 &&
                                    selectedUserTokensOfDuck) ||
                                (stakingTab === 1 &&
                                    selectedUserTokensOfDuckling) ||
                                (stakingTab === 2 && selectedUserTokensOfAlpha)
                            }
                            options={
                                (stakingTab === 0 && userTokenIdsOfDuck) ||
                                (stakingTab === 1 && userTokenIdsOfDuckling) ||
                                (stakingTab === 2 && userTokenIdsOfAlpha)
                            }
                            isMulti={true}
                            NoOptionsMessage="No options"
                            onChange={(selected) =>
                                onUserTokensChange(selected)
                            }
                        /> */}
                        <div className="Card_ButtonContainer">
                            <button
                                className="Card_ActionBtn Card_Green"
                                onClick={() => onClickApproveOrStake()}
                            >
                                {stakingApproveText()}
                            </button>
                            <button
                                className="Card_MaxBtn"
                                onClick={() => onClickMaxStake()}
                            >
                                MAX
                            </button>
                        </div>
                    </div>
                </div>
                <div className="Card_Wrapper">
                    {/* <img
                        className="Card_DesktopBackground"
                        src={svgs.red_card_background}
                        alt="Background"
                        draggable="false"
                    /> */}
                    <img
                        className="Card_DesktopBackground"
                        src="/images/red.svg"
                        alt="Background"
                        draggable="false"
                    />
                    <img
                        className="Card_MobileBackground"
                        src='/images/red-mobile.png'
                        alt="Background"
                        draggable="false"
                    />
                    {/* <h1><img alt="" src="/images/unstaking.png" /></h1> */}
                    <div className="Card_Selector">
                        <button
                            className={
                                unStakingTab === 0 ? "Card_Red duck_button" : undefined
                            }
                            onClick={() => setUnStakingTab(0)}
                        >
                            DUCK
                        </button>
                        <button
                            className={
                                unStakingTab === 1 ? "Card_Red alpha_button" : undefined
                            }
                            onClick={() => setUnStakingTab(1)}
                        >
                            DUCKLING
                        </button>
                       
                    </div>
                    <div className="Card_GridContainer">
                        <div>
                            <img
                                src={unStakingTab === 0 ? "/images/Duck.png" : unStakingTab === 1 ? "/images/Duckling.png" : "/images/alpha.png"}
                                alt="Duck"
                                draggable="false"
                            />
                            <h2>
                                {(unStakingTab === 0 &&
                                    "DUCK: " + stakedTokenIdsOfDuck.length) ||
                                    (unStakingTab === 1 &&
                                        "DUCKLING: " +
                                        (stakedTokenIdsOfDuckling.length
                                            ? stakedTokenIdsOfDuckling.length
                                            : 0)) /* ||
                                    (unStakingTab === 2 &&
                                        "ALPHA: " +
                                        (stakedTokenIdsOfAlpha.length
                                            ? stakedTokenIdsOfAlpha.length
                                        : 0)) */}
                            </h2>
                            <h3>Staked</h3>
                        </div>
                    </div>
                    <div className="Card_InputContainer">
                        <label style={{ marginBottom: "-10px" }}>
                            {(unStakingTab === 0 && "Ducks to unstake") ||
                                (unStakingTab === 1 && "Ducklings to unstake") ||
                                (unStakingTab === 2 && "Alphas to unstake")}
                        </label>
                    
                        { <Select
                            
                            value={
                                (unStakingTab === 0 &&
                                    selectedStakedTokensOfDuck) ||
                                (unStakingTab === 1 &&
                                    selectedStakedTokensOfDuckling) ||
                                (unStakingTab === 2 && selectedStakedTokensOfAlpha)
                            }
                            options={
                                (unStakingTab === 0 && stakedTokenIdsOfDuck) ||
                                (unStakingTab === 1 &&
                                    stakedTokenIdsOfDuckling) //||
                               // (unStakingTab === 2 && stakedTokenIdsOfAlpha)
                            }
                            isMulti={true}
                            NoOptionsMessage="No options"
                            closeMenuOnSelect={false}
                            onChange={(selected) =>
                                onStakedTokensChange(selected)
                            }
                        /> }
                        <div className="Card_ButtonContainer">
                            <button
                                className="Card_ActionBtn Card_Red"
                                onClick={() => onClickUnstake()}
                            >
                                UNSTAKE
                            </button>
                            <button
                                className="Card_MaxBtn"
                                onClick={() => onClickMaxUnstake()}
                            >
                                MAX
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GridContainer;
